import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      franco: 60,
      deliveryMethod: '',
      defaultDeliveryMondialRelayPrice: 5.5,
      defaultDeliveryPostalPrice: 7.5,
    };
  },

  computed: {
    ...mapGetters({
      cart: 'shop/cart',
      coupon: 'app/coupon',
    }),

    totalProducts() {
      let total = 0;

      Object.keys(this.cart).forEach(key => {
        total += this.cart[key].price * this.cart[key].quantity;

        if (this.cart[key].options !== undefined) {
          if (this.cart[key].options.length > 0) {
            this.cart[key].options.forEach(option => {
              total +=
                this.cart[key].quantity * (option.price * (option.quantity || 1));
            });
          }
        }
      });

      return total;
    },

    hiddenType() {
      // Get all hidden types
      const hiddenType = this.cart.map(a => a.hiddenType);

      // Set new array with unique hidden type
      return [...new Set(hiddenType)];
    },

    deliveryPostalPrice() {
      if (
        this.coupon.type &&
        (this.coupon.type === 'delivery' ||
          this.coupon.type === 'percent-delivery')
      ) {
        return 0;
      }

      return this.defaultDeliveryPostalPrice;
    },

    deliveryMondialRelayPrice() {
      if (
        this.totalProducts + ((this.coupon && this.coupon.amount) || 0) >=
        this.franco
      ) {
        return 0;
      }

      return this.defaultDeliveryMondialRelayPrice;
    },

    deliveryPrice() {
      let price =
        this.deliveryMethod === 'mondial-relay'
          ? this.deliveryMondialRelayPrice
          : this.deliveryPostalPrice;

      // if only gift hidden type is set
      if (this.hiddenType.length <= 1 && this.hiddenType[0] === 'gift') {
        price = 0;
      }

      if (
        this.coupon.type &&
        (this.coupon.type === 'delivery' ||
          this.coupon.type === 'percent-delivery')
      ) {
        price = 0;
      }

      if (
        this.totalProducts + ((this.coupon && this.coupon.amount) || 0) >=
        this.franco &&
        this.deliveryMethod === 'mondial-relay'
      ) {
        price = 0;
      }

      return price;
    },
  },
};
